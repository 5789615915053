import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import ErrorBoundary from "../Error/ErrorBoundary";
import ErrorMessage from "../../pages/errors/ErrorMessage";
import useEmailFormatValidator from "../../Hooks/useEmailValidation";
import GreyCheckbox from "../../Utils/GreyCheckbox";
import { ApiCall } from "../ApiCall";
import { ProfileLogin, Accounts } from '../../Redux/customerSlice';
import { updatePageMeta } from "../../Redux/siteWideSettingsSlice";
import { Link } from 'react-router-dom';
import { LogError } from "../Error/LogError";

function LoginCustomer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectUrl = queryParams.get('redirect');
  const getEmailValidationState = useEmailFormatValidator();
  const [email, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [disableFields, setdisableFields] = useState(false);
  const [keepSignedIn, setKeepSignedIn] = useState(false);
  const emailValidationState = getEmailValidationState(email);
  const [ResultsMessage, setResultsMessage] = useState('');
  const EmployeeLoggedIn = useSelector((state) => state.siteWideSettings?.EmployeeUser?.LoggedIn);
  dispatch(updatePageMeta("Customer Login"));

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoginLoading(true);
    setdisableFields(true);

    setEmailError("");
    setPasswordError("");
    document.getElementById('email').style.border = '1px solid #ced4da';
    document.getElementById('password').style.border = '1px solid #ced4da';

    if (!emailValidationState.isValid) {
      document.getElementById('email').style.border = '1px solid red';
      setEmailError(emailValidationState.message);
      return;
    }
    if (password === '') {
      document.getElementById('password').style.border = '1px solid red';
      setPasswordError('Password is required');
      return;
    }

    try {
      const data = {
        'Email': email,
        'Password': password
      };
      const response = await ApiCall('usp_Tcp_LoginAuth', data);
      const LoginResults = response?.data?.Table[0]['Results'];
      setResultsMessage(response?.data?.Table[0]['ResultsMessage']);
      const UserProfile = response?.data?.Table1;

      if (LoginResults === 'Successful') {
        UserProfile[0]['isLoggedIn'] = true;
        UserProfile[0]['keepSignedIn'] = keepSignedIn;
        await dispatch(ProfileLogin(UserProfile));

        // Check for MFA requirements and navigate to MFA page if necessary
        if (
          LoginResults === 'Successful' &&
          (UserProfile[0]?.MfaAuthenticator === true ||
            UserProfile[0]?.MfaSms === true ||
            UserProfile[0]?.MfaEmail === true) &&
          UserProfile[0]?.MfaLoggedIn === false
        ) {
          navigate('/customer/login/Mfa');
        }

        var dataA = {
          'Email': email
        }
        const responseA = await ApiCall('tq_ReturnCustomersForEmail', dataA, 'Tcp')
        const AccountsData = responseA?.data?.Table;
        await dispatch(Accounts(AccountsData));

        if (redirectUrl) {
          navigate(redirectUrl);
        } else if (AccountsData.length > 1) {
          navigate('/customer/accounts');
        } else {
          navigate('/home');
        }
      }
    } catch (error) {
      await LogError(error.message, 'Customer Login', 'LoginCustomer');
    } finally {
      setLoginLoading(false);
      setdisableFields(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setEmailError("");
      setPasswordError("");
    }, 30000);
    return () => clearTimeout(timer);
  }, [emailError, passwordError]);

  return (
    <ErrorBoundary componentName="CustomerLogin" fallback={<ErrorMessage />}>
      <div className="forms-container">
        <h2>Account Login</h2>
        <form onSubmit={handleLogin} autoComplete="on">
          <div className="forms-box">
            <div className="input-group">
              <label htmlFor="email">E-mail :</label>
              <input
                type="email"
                id="email"
                name="email"
                autoComplete="username email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setUserEmail(e.target.value)}
                disabled={disableFields}
              />
              {emailError && <div className="error-message">{emailError}</div>}
            </div>
            <div className="input-group">
              <label htmlFor="password">Password :</label>
              <input
                type="password"
                id="password"
                name="password"
                autoComplete="current-password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={disableFields}
              />
              {passwordError && <div className="error-message">{passwordError}</div>}
              <div className="input-controls">
                <GreyCheckbox
                  label="Keep Me Signed In"
                  checked={keepSignedIn}
                  onChange={(e) => setKeepSignedIn(e)}
                />
                <Link
                  to={`/customer/passwordreset${redirectUrl ? `?redirect=${redirectUrl}` : ''}`}
                  className="forgot-password text-decoration-none hover-underline"
                >
                  Forgot Password?
                </Link>
              </div>
            </div>
            <button type="submit" className="btn btn-primary w-100">
              {!loginLoading ? <span>LOG IN</span> : <><span style={{ paddingRight: '10px' }}>Logging In</span><i className="fa fa-spinner fa-spin " /></>}
            </button>
            {ResultsMessage && (
              <div className="alert alert-warning mt-3">{ResultsMessage}</div>
            )}
            <Link
              to={`/customer/signup${redirectUrl ? `?redirect=${redirectUrl}` : ''}`}
              className="btn btn-link text-center w-100 mt-3 text-dark hover-underline"
            >
              {redirectUrl ? "First time user? Set up your password here" : "Create a new account"}
            </Link>
          </div>
        </form>
        {EmployeeLoggedIn && (
          <div>
            <Link to="/employee/users" className="btn btn-primary">View All Users</Link>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
}

export default LoginCustomer;
