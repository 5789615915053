import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../Error/ErrorBoundary";
import ErrorMessage from "../../pages/errors/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { Account } from "../../Redux/customerSlice";
import { updatePageMeta } from "../../Redux/siteWideSettingsSlice";
import "../../styles/_Accounts.scss";

function Accounts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customerAccounts = useSelector((state) => state?.customer?.accounts);
  dispatch(updatePageMeta("Customer Accounts"));

  const handleAccountSelect = (accountId) => {
    const AccountFound = customerAccounts.filter(account => account.CustID === accountId)[0];
    dispatch(Account(AccountFound));
    navigate(`/customer/profile`);
  };

  return (
    <ErrorBoundary componentName="CustomerAccounts" fallback={<ErrorMessage />}>
      <div className="accounts-forms-container">
        <div className="forms-box">
          <h2>Choose An Account:</h2>
          <div className="input-group">
            {customerAccounts && customerAccounts.length > 0 && (
              customerAccounts.map((account) => (
                <div
                  key={account.CustID}
                  className="accounts-name"
                  onClick={() => handleAccountSelect(account.CustID)}
                  style={{ borderRadius: '8px', }}
                >
                  {account.CustName}
                </div>
              )))}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default Accounts;
