import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ApiCall } from '../../../components/ApiCall';
import { useDispatch } from 'react-redux';
import { ProfileLogin } from '../../../Redux/customerSlice';
import Loading from '../../../components/loading';
import FormattedCurrency from '../../../components/DataManipulation/FormatCurrency';
import FormattedDate from '../../../components/DataManipulation/FormatDate';

function User() {
  const dispatch = useDispatch();
  const { ID } = useParams();
  const [User, setUser] = useState(null);
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [carts, setCarts] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const fetchUser = async (WebsiteUserID, RequestType = null, FirstName = null, LastName = null, Email = null, Active = null, Deleted = null) => {
    setIsLoading(true);
    var data = {
      'WebsiteID': '1',
      'WebsiteUserID': WebsiteUserID
    };

    if (RequestType !== null) data['RequestType'] = RequestType;
    if (FirstName !== null) data['FirstName'] = FirstName;
    if (LastName !== null) data['LastName'] = LastName;
    if (Email !== null) data['Email'] = Email;
    if (Active !== null) data['Active'] = Active;
    if (Deleted !== null) data['Deleted'] = Deleted;
    const response = await ApiCall('usp_Webstore_Users', data);
    setIsLoading(false);
    return response?.data?.Table[0] || {};
  };

  const fetchUserCarts = async (WebsiteUserID) => {
    const data = {
      'WebsiteUserID': WebsiteUserID
    };
    const response = await ApiCall('usp_Webstore_UserCarts', data);
    return response?.data?.Table || [];
  };

  useEffect(() => {
    const getUserData = async () => {
      const userData = await fetchUser(ID);
      setUser(userData);
      const userCarts = await fetchUserCarts(ID);
      setCarts(userCarts);
    };
    getUserData();
  }, [ID]);

  const toggleEditMode = (e) => {
    e.preventDefault();
    setIsEditMode(!isEditMode);
  };

  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    setUser(prevUser => ({
      ...prevUser,
      [id]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const userData = await fetchUser(ID, 'Update', User?.FirstName, User?.LastName, User?.Email, User?.Active);
    setUser(userData);
    setIsEditMode(false);
  };

  const handleLoginAsCustomer = async (e) => {
    e.preventDefault();
    const data = {
      'Email': User?.Email,
      'Password': '',
      'LoginAsUserID': ID
    }
    const response = await ApiCall('usp_Tcp_LoginAuth', data);
    const LoginResults = response?.data?.Table[0]['Results'];
    const UserProfile = response?.data?.Table1;

    if (LoginResults === 'Successful') {
      UserProfile[0]['isLoggedIn'] = true;
      await dispatch(ProfileLogin(UserProfile));
      navigate('/home');
    }
  };

  const handleDeleteUser = async (e) => {
    e.preventDefault();
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteUser = async () => {
    const userData = await fetchUser(ID, 'Delete');
    setUser(userData);
    setShowDeleteConfirmation(false);
  };

  const cancelDeleteUser = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <div className="container-fluid py-5">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <Link to="/employee/users" className="btn btn-outline-primary">
                Back To Users
              </Link>
              {!User?.Deleted && (
                <button className="btn btn-outline-primary" onClick={handleLoginAsCustomer}>
                  Login As Customer
                </button>
              )}
            </div>
            <div className="card border-0 rounded-3">
              {User?.Deleted && (
                <div className="alert alert-danger m-3" role="alert">
                  This user has been deleted
                </div>
              )}
              <div className="card-body p-4">
                <form>
                  {['FirstName', 'LastName', 'Email'].map((field) => (
                    <div className="mb-4 row align-items-center" key={field}>
                      <label htmlFor={field} className="col-sm-3 form-label text-muted fw-medium mb-0">
                        {field.replace(/([A-Z])/g, ' $1').trim()}:
                      </label>
                      <div className="col-sm-9">
                        <input
                          type={field === 'Email' ? 'email' : 'text'}
                          className={`form-control form-control-lg border ${isEditMode ? 'bg-white border-primary' : 'border-0 bg-light'}`}
                          id={field}
                          value={User?.[field] || ''}
                          onChange={handleInputChange}
                          readOnly={!isEditMode}
                        />
                        {field === 'Email' && !User?.EmailConfirmed && (
                          <div className="text-danger mt-2">
                            Unconfirmed Email: Ask the customer to reset their password and use the confirmation link sent to their email.
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="mb-4 row align-items-center">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Active"
                          checked={User?.Active || false}
                          onChange={handleInputChange}
                          disabled={!isEditMode}
                        />
                        <label className="form-check-label text-muted fw-medium" htmlFor="Active">
                          Active
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-4">
                    <div>
                      {!User?.Deleted && !isEditMode ? (
                        <button className="btn btn-primary px-4" onClick={toggleEditMode}>
                          Edit
                        </button>
                      ) : !User?.Deleted && isEditMode ? (
                        <>
                          <button className="btn btn-primary px-4" onClick={handleSave}>
                            Save
                          </button>
                          <button className="btn btn-secondary px-4" onClick={toggleEditMode}>
                            Cancel
                          </button>
                        </>
                      ) : null}
                    </div>
                    <div>
                      {!User?.Deleted && (
                        <button className="btn btn-outline-danger px-4" onClick={handleDeleteUser}>
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                  {showDeleteConfirmation && (
                    <div className="alert alert-warning mt-3" role="alert">
                      <p>Are you sure you want to delete this user?</p>
                      <button className="btn btn-danger me-2" onClick={confirmDeleteUser}>
                        Confirm
                      </button>
                      <button className="btn btn-secondary" onClick={cancelDeleteUser}>
                        Cancel
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="card border-0 rounded-3 mt-4">
        <div className="card-body p-4">
          <h4 className="card-title">Carts</h4>
          {carts.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Cart ID</th>
                  <th>Order #</th>
                  <th>Cart Name</th>
                  <th>Order Type</th>
                  <th>Status</th>
                  <th>Date Created</th>
                  <th>Date Order Placed</th>
                  <th>Order Total</th>
                  <th>Email Receipt</th>
                  <th>Products Below Min</th>
                  <th>Logos Below Min</th>
                </tr>
              </thead>
              <tbody>
                {carts.map((cart, index) => (
                  <tr key={index}>
                    <td>{cart.WebOrderID}</td>
                    <td>{cart.OrderNum}</td>
                    <td>{cart.CartName}</td>
                    <td>{cart.OrderType}</td>
                    <td>{cart.Status}</td>
                    <td><FormattedDate date={cart.DateCreated} dateFormat="MM/dd/yyyy h:mm a" /></td>
                    <td>{cart.Status !== 'Open' && <FormattedDate date={cart.DateOrderPlaced} dateFormat="MM/dd/yyyy h:mm a" />}</td>
                    <td><FormattedCurrency value={cart.OrderTotal} /></td>
                    <td>{cart.EmailReceipt}</td>
                    <td>{cart.ProductsBelowMin ? 'Yes' : 'No'}</td>
                    <td>{cart.LogosBelowMin ? 'Yes' : 'No'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No carts available for this user.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default User;
