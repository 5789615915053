import React from 'react';

const Tracking = ({ trackingNumber }) => {
  const getCarrierUrl = (tracking) => {
    // Remove any spaces from tracking number
    const cleanTracking = tracking.replace(/\s+/g, '');
    
    // Determine carrier based on tracking number pattern
    if (/^1Z[A-Z0-9]{16}$/i.test(cleanTracking)) {
      // UPS
      return `https://www.ups.com/track?loc=en_US&tracknum=${cleanTracking}`;
    } else if (/^\d{12,14}$/.test(cleanTracking)) {
      // FedEx
      return `https://www.fedex.com/fedextrack/?trknbr=${cleanTracking}`;
    } else if (/^9\d{15,21}$/.test(cleanTracking)) {
      // USPS
      return `https://tools.usps.com/go/TrackConfirmAction?tLabels=${cleanTracking}`;
    }
    
    // If no match, return null
    return null;
  };

  const trackingUrl = getCarrierUrl(trackingNumber);

  return trackingUrl ? (
    <a 
      href={trackingUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      {trackingNumber}
    </a>
  ) : (
    <span>{trackingNumber}</span>
  );
};

export default Tracking;
