import { createSlice } from "@reduxjs/toolkit";
import getStorageArrayValue from "../components/Security/getStorageArrayValue";
import { encryptUsingAES256Base64, decryptUsingAES256Base64 } from "../components/Security/crypt";

let CustomerCookie = 'CustomerProfile'
var MfaCookie = process.env.REACT_APP_DATABASE + "FixMe";//TODO: Update to correct value

const getLocalMfaValue = () => {
  const Mfa = localStorage.getItem(MfaCookie);
  if (!Mfa) {
    return false;
  }
  else {
    return JSON.parse(decryptUsingAES256Base64(Mfa));
  }
}

export const ProfileLogin = (UserProfile) => async (dispatch) => {
  await dispatch(setProfile(UserProfile));
}

export const ProfileAddress = (UserProfile) => async (dispatch) => {
  await dispatch(setProfileAddress(UserProfile));
}

export const PaymentMethods = (PaymentMethods) => async (dispatch) => {
  await dispatch(setPaymentMethods(PaymentMethods));
}

export const Account = (Account) => async (dispatch) => {
  await dispatch(setAccount(Account));
}

export const Accounts = (Accounts) => async (dispatch) => {
  await dispatch(setAccounts(Accounts));
}

export const ProfileLogout = () => async (dispatch) => {
  await dispatch(clearProfile());
}


const CustomerDefault = {
  isLoggedIn: getStorageArrayValue(CustomerCookie, "isLoggedIn") || false,
  websiteUserID: getStorageArrayValue(CustomerCookie, "websiteUserID") || '',
  message: '', //TODO: remove
  organization: "", //TODO: remove
  firstName: getStorageArrayValue(CustomerCookie, "firstName") || '',
  lastName: getStorageArrayValue(CustomerCookie, "lastName") || '',
  address1: getStorageArrayValue(CustomerCookie, "address1") || '',
  address2: getStorageArrayValue(CustomerCookie, "address2") || '',
  city: getStorageArrayValue(CustomerCookie, "city") || '',
  country: getStorageArrayValue(CustomerCookie, "country") || '',
  state: getStorageArrayValue(CustomerCookie, "state") || '',
  zip: getStorageArrayValue(CustomerCookie, "zip") || '',
  phone: getStorageArrayValue(CustomerCookie, "phone") || '',
  email: getStorageArrayValue(CustomerCookie, "email") || '',
  salesRepId2: getStorageArrayValue(CustomerCookie, "salesRepId2") || '',
  salesRep2Email: getStorageArrayValue(CustomerCookie, "salesRep2Email") || '',
  account: getStorageArrayValue(CustomerCookie, "account") || '',
  accounts: getStorageArrayValue(CustomerCookie, "accounts") || [],
  paymentMethods: getStorageArrayValue(CustomerCookie, "paymentMethods") || [],
  MfaLoggedin: getLocalMfaValue(CustomerCookie, "MfaLoggedin") || false,
  MfaSms: getStorageArrayValue(CustomerCookie, "MfaSms") || false,
  MfaEmail: getStorageArrayValue(CustomerCookie, "MfaEmail") || false,
  MfaAuthenticator: getStorageArrayValue(CustomerCookie, "MfaAuthenticator") || false,
}

export const customerSlice = createSlice({
  name: 'Customer',
  initialState: CustomerDefault,
  reducers: {
    setProfile: (state, action) => {
      const { isLoggedIn, WebsiteUserID, FirstName, LastName, Email, keepSignedIn, MfaLoggedIn } = action.payload[0];
      state.isLoggedIn = isLoggedIn;
      state.websiteUserID = WebsiteUserID;
      state.firstName = FirstName;
      state.lastName = LastName;
      state.email = Email;
      state.MfaLoggedin = MfaLoggedIn || false;
      state.MfaSms = action.payload.MfaSms || false;
      state.MfaEmail = action.payload.MfaEmail || false;
      state.MfaAuthenticator = action.payload.MfaAuthenticator || false;

      if (keepSignedIn) {
        localStorage.setItem(CustomerCookie, encryptUsingAES256Base64(JSON.stringify(state)));
      }
      sessionStorage.setItem(CustomerCookie, encryptUsingAES256Base64(JSON.stringify(state)));
    },
    setMfa: (state, action) => {
      const MfaLoggedIn = action.payload === 'Successful';
      state.User.MfaLoggedIn = MfaLoggedIn;
      localStorage.setItem(MfaCookie, encryptUsingAES256Base64(JSON.stringify(MfaLoggedIn)));
    },
    setProfileAddress: (state, action) => {
      const { Addr1, Addr2, City, Country, Region, PostalCode, Phone } = action.payload[0];
      state.address1 = Addr1;
      state.address2 = Addr2;
      state.city = City;
      state.country = Country;
      state.state = Region;
      state.zip = PostalCode;
      state.phone = Phone;

      // if (keepSignedIn) {
      //   localStorage.setItem(CustomerCookie, encryptUsingAES256Base64(JSON.stringify(state)));
      // }
      sessionStorage.setItem(CustomerCookie, encryptUsingAES256Base64(JSON.stringify(state)));
    },
    setPaymentMethods: (state, action) => {
      state.paymentMethods = action.payload;
      // if (localStorage.getItem(CustomerCookie) !== null) {
      //   localStorage.setItem(CustomerCookie, encryptUsingAES256Base64(JSON.stringify(state)));
      // }
      sessionStorage.setItem(CustomerCookie, encryptUsingAES256Base64(JSON.stringify(state)));
    },
    setAccount: (state, action) => {
      state.account = action.payload.CustID;
      state.salesRepId2 = action.payload.SalesRepId2;
      state.salesRep2Email = action.payload.SalesRep2Email;

      if (localStorage.getItem(CustomerCookie) !== null) {
        localStorage.setItem(CustomerCookie, encryptUsingAES256Base64(JSON.stringify(state)));
      }
      sessionStorage.setItem(CustomerCookie, encryptUsingAES256Base64(JSON.stringify(state)));
    },
    setAccounts: (state, action) => {
      state.accounts = action.payload.sort((a, b) => a.CustName.localeCompare(b.CustName));
      if (action.payload.length === 1) {
        state.account = action.payload[0].CustID;
        state.salesRepId2 = action.payload[0].SalesRepId2;
        state.salesRep2Email = action.payload[0].SalesRep2Email;
      }

      if (localStorage.getItem(CustomerCookie) !== null) {
        localStorage.setItem(CustomerCookie, encryptUsingAES256Base64(JSON.stringify(state)));
      }
      sessionStorage.setItem(CustomerCookie, encryptUsingAES256Base64(JSON.stringify(state)));
    },
    setProfileMessage: (state, action) => {
      state.message = action.payload;
    },
    clearProfile: (state) => {
      localStorage.removeItem(CustomerCookie);
      sessionStorage.removeItem(CustomerCookie);
      localStorage.removeItem(MfaCookie);
      const expires = '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie = 'MfaCookie' + expires;
      setProfile({});
      return { ...CustomerDefault };
    }
  }
});

export const {
  setProfile,
  setProfileAddress,
  setPaymentMethods,
  setAccount,
  setAccounts,
  setProfileMessage,
  clearProfile,
  setMfa,
} = customerSlice.actions;

export default customerSlice.reducer;
