import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiCall } from '../../../components/ApiCall';
import Loading from '../../../components/loading';

function Users() {
  const [Users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [isLoading, setIsLoading] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const [showDeleted, setShowDeleted] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      const data = {
        'WebsiteID': '1'
      };
      const response = await ApiCall('usp_Webstore_Users', data);
      setUsers(response?.data?.Table || []);
      setIsLoading(false);
    };
    fetchUsers();
  }, []);

  const filteredUsers = Users.filter(user => {
    const matchesSearch = 
      user.CustID?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.FirstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.LastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.Email?.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesActive = showActive ? user.Active : true;
    const matchesDeleted = showDeleted ? true : !user.Deleted;

    return matchesSearch && matchesActive && matchesDeleted;
  });

  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container-fluid py-4">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Search Customers And Or Contacts"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button className="btn btn-outline-primary" type="button">
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <div className="form-check me-4">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="activeCheck"
                    checked={showActive}
                    onChange={(e) => setShowActive(e.target.checked)}
                  />
                  <label className="form-check-label text-muted fw-medium" htmlFor="activeCheck">
                    Show Active
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="deletedCheck"
                    checked={showDeleted}
                    onChange={(e) => setShowDeleted(e.target.checked)}
                  />
                  <label className="form-check-label text-muted fw-medium" htmlFor="deletedCheck">
                    Show Deleted
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-flex align-items-center">
                <label className="text-muted fw-medium me-2">Show:</label>
                <select className="form-select form-select-lg" value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))}>
                  <option value={100}>100</option>
                  <option value={250}>250</option>
                  <option value={500}>500</option>
                </select>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>FirstName</th>
                  <th>LastName</th>
                  <th>Email</th>
                  <th>Email Confirmed</th>
                  <th>Active</th>
                  <th>Deleted</th>
                  <th>Carts</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.length > 0 ? (
                  currentUsers.map(user => (
                    <tr key={user.WebsiteUserID}>
                      <td>{user.FirstName}</td>
                      <td>{user.LastName}</td>
                      <td>{user.Email}</td>
                      <td>{user.EmailConfirmed ? 'Yes' : 'No'}</td>
                      <td>{user.Active ? 'Yes' : 'No'}</td>
                      <td>{user.Deleted ? 'Yes' : 'No'}</td>
                      <td>{user.ShoppingCartCount || 0}</td>
                      <td>
                        <Link
                          to={`/employee/user/${user.WebsiteUserID}`}
                          className="btn btn-primary"
                        >
                          View/Edit
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">No users found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {filteredUsers.length > itemsPerPage && (
            <nav aria-label="Page navigation" className="mt-4">
              <ul className="pagination justify-content-center">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                    aria-label="Previous"
                  >
                    &laquo;
                  </button>
                </li>
                {[...Array(Math.ceil(filteredUsers.length / itemsPerPage)).keys()].map(number => (
                  <li key={number} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                    <button
                      className="page-link"
                      onClick={() => paginate(number + 1)}
                      aria-label={`Go to page ${number + 1}`}
                    >
                      {number + 1}
                    </button>
                  </li>
                ))}
                <li className={`page-item ${currentPage === Math.ceil(filteredUsers.length / itemsPerPage) ? 'disabled' : ''}`}>
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(filteredUsers.length / itemsPerPage)))}
                    aria-label="Next"
                  >
                    &raquo;
                  </button>
                </li>
              </ul>
            </nav>
          )}
        </>
      )}
    </div>
  );
}

export default Users;
