import React, { useState } from "react";
import axios from 'axios';
import { cryptAPIKey, encryptUsingAES256Base64 } from "./Security/crypt";
import { EditConentHtmlUrl } from '../config/apiconfig';
import { useSelector } from "react-redux";
import ErrorBoundary from "./Error/ErrorBoundary";
import ErrorMessage from "../pages/errors/ErrorMessage";

function HtmlEditor({ adId, initialHtmlValue, loggedIn }) {
  const [adIdValue] = useState(adId);
  const [htmlValue, setHtmlValue] = useState(initialHtmlValue);
  const [showEdit, setShowEdit] = useState(false);
  const CMSEditButton = useSelector((state) => state.siteWideSettings?.CMSEditButton);

  const handleCommitSave = async () => {
    try {
      var data = {
        AdID: adIdValue,
        Html: htmlValue,
      };

      var requestHeaders = {
        'Content-Type': 'application/json; charset=utf-8',
        'X-ApiKey': cryptAPIKey(process.env.REACT_APP_SECRET_KEY)
      };

      var encryptedData = encryptUsingAES256Base64(JSON.stringify(data));
      //const response = await axios.post(EditConentHtmlUrl, encryptedData, { headers: requestHeaders, });
      await axios.post(EditConentHtmlUrl, encryptedData, { headers: requestHeaders, });
    } catch (error) {
      //TODO: build out error handler and centralize the api caller
      // Log the error message
      // console.error("Request failed:", error.message);

      // Check if the error is an axios error
      if (error.response) {
        // Log additional error information
        // console.error("Status code:", error.response.status);
        // console.error("Response headers:", error.response.headers);
        // console.error("Response data:", error.response.data);
      } else if (error.request) {
        // Log the request that caused the error
        // console.error("Request:", error.request);
      } else {
        // Log the error if it was not due to a request or response
        // console.error("Error:", error);
      }
    }

    setShowEdit(!showEdit);
  };

  const handleEdit = () => {
    setShowEdit(!showEdit);
    setHtmlValue(initialHtmlValue);
  }

  const handleCancel = () => {
    setShowEdit(!showEdit);
    setHtmlValue(initialHtmlValue);
  }

  const handleSave = () => {
    handleCommitSave();
    setHtmlValue(htmlValue);
    setShowEdit(false);
  };

  return (
    <ErrorBoundary componentName="Html Editor" fallback={<ErrorMessage />}>
      <div>
        <div
          dangerouslySetInnerHTML={{ __html: htmlValue }}
          style={{ overflow: "auto" }}
        />
        {loggedIn && showEdit && CMSEditButton && (
          <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "10px",
          }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "90%",
              }}
            >
              <textarea
                value={htmlValue}
                onChange={(e) => setHtmlValue(e.target.value)}
                style={{
                  width: "100%",
                  height: "250px",
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <button
                  className="btn btn-secondary"
                  style={{ marginRight: "10px" }}
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button className="btn btn-primary" onClick={handleSave}>
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
        {loggedIn && !showEdit && CMSEditButton && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "10px",
            }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <button
                className="btn btn-primary"
                style={{ marginRight: "10px" }}
                onClick={handleEdit}
              >
                Edit
              </button>
            </div>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
}

export default HtmlEditor;
