import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaSpinner } from 'react-icons/fa';
import ErrorBoundary from "../Error/ErrorBoundary";
import ErrorMessage from "../../pages/errors/ErrorMessage";
import useEmailFormatValidator from "../../Hooks/useEmailValidation";
import PasswordStrengthMeter from "../PasswordStrengthMeter";
import { ApiCall } from "../ApiCall";
import SendEmail from "../SendEmail";
import ReactDOMServer from 'react-dom/server';
import PasswordResetTemplate from '../EmailTemplates/PasswordReset';
import { updatePageMeta } from "../../Redux/siteWideSettingsSlice";
import { LogError } from "../Error/LogError";

// pageName is provided by two differenct parents. use caution when updating the pageName prop. 
function PasswordReset({ pageName }) {
  const dispatch = useDispatch();
  const customerEmail = useSelector((state) => state?.customer?.email);
  const initialEmail = pageName === "security" ? customerEmail : '';
  const [email, setEmail] = useState(initialEmail);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errors, setErrors] = useState({ email: "", password: "", confirmPassword: "" });
  const [resetRequested, setResetRequested] = useState(false);
  const [results, setResults] = useState('');
  const [resultsMessage, setResultsMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const getEmailValidationState = useEmailFormatValidator();
  const emailValidationState = getEmailValidationState(email);
  dispatch(updatePageMeta("Password Reset"));
  const handleResetPassword = async () => {
    setErrors({ email: "", password: "", confirmPassword: "" });

    // Reset border colors
    document.getElementById('email').style.border = '1px solid #ced4da';
    document.getElementById('newpassword').style.border = '1px solid #ced4da';
    document.getElementById('confirmNewPassword').style.border = '1px solid #ced4da';
    let isValid = true;
    const newErrors = {};

    if (!emailValidationState.isValid) {
      newErrors.email = emailValidationState.message;
      isValid = false;
    }
    if (newPassword.trim() === '') {
      newErrors.password = 'Password is required';
      isValid = false;
    }
    if (newPassword !== confirmNewPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
      isValid = false;
    }

    setErrors(newErrors);

    if (isValid) {
      setLoading(true);
      setResetRequested(true);
      try {
        const data = {
          'Email': email,
          'NewPassword': newPassword
        }

        const response = await ApiCall('usp_Tcp_ResetPassword', data);
        setResults(response?.data?.Table[0]['Results']);
        setResultsMessage(response?.data?.Table[0]['ResultsMessage']);
        if (response?.data?.Table[0]['Results'] === 'Successful') {
          const searchParams = new URLSearchParams(window.location.search);
          const redirectParam = searchParams.get('redirect');
          const redirectUrl = redirectParam ? `?redirect=${redirectParam}` : '';

          const EmailBody = ReactDOMServer.renderToString(
            <PasswordResetTemplate
              resetPasswordURL={`${window.location.origin}${process.env.PUBLIC_URL}/customer/passwordconfirmation/${response?.data?.Table[0]['ResetRowGuid']}${redirectUrl}`}
            />
          );
          await SendEmail(email, '', 'Triple Crown Products Password Reset Confirmation', EmailBody, 'noreply@triplecrownproducts.com');
        }
      } catch (error) {
        await LogError(error, 'Password Reset', 'PasswordReset');
        setResultsMessage('An error occurred while resetting your password');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <ErrorBoundary componentName="ResetPassword" fallback={<ErrorMessage />}>
      <div className={pageName === "passwordreset" ? "forms-container positioning" : "security-forms-container"}>
        {results !== 'Successful' && (
          <div className="text-center">
            <h2>{pageName !== "security" ? 'Password Reset' : 'Set your account password'}</h2>
          </div>
        )}
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6">
              {results === 'Successful' ? (
                <div className="text-center p-4">
                  <h4 className="fw-bold mb-3">Password Reset Successfully</h4>
                  <p className="mb-3">Your password has been reset successfully.</p>
                  <p className="mb-2">We've sent a confirmation email to <strong>{email}</strong>. Please check your inbox for further instructions.</p>
                  <br /><br />
                  <p className="small text-muted">Note: If you don't see the email in your inbox, please check your spam folder.</p>
                </div>
              ) : (
                <>
                  {pageName === "passwordreset" && (
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">EMAIL:</label>
                      <input
                        type="email"
                        id="email"
                        className={`form-control ${errors.email ? 'input-error' : ''}`}
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} autoComplete="username"
                        disabled={resetRequested}
                      />
                      {errors.email && <div className="text-danger">{errors.email}</div>}
                    </div>
                  )}
                  <div className="mb-3">
                    <label htmlFor="newpassword" className="form-label">NEW PASSWORD:</label>
                    <input
                      type="password"
                      id="newpassword"
                      className={`form-control ${errors.password ? 'input-error' : ''}`}
                      placeholder="Enter your new password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)} autoComplete="new-password"
                      disabled={resetRequested}
                    />
                    {errors.password && <div className="text-danger">{errors.password}</div>}
                    {newPassword.length > 0 && (
                      <PasswordStrengthMeter password={newPassword} />
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="confirmNewPassword" className="form-label">CONFIRM NEW PASSWORD:</label>
                    <input
                      type="password"
                      id="confirmNewPassword"
                      className={`form-control ${errors.confirmPassword ? 'input-error' : ''}`}
                      placeholder="Confirm your new password"
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)} autoComplete="new-password"
                      disabled={resetRequested}
                    />
                    {errors.confirmPassword && <div className="text-danger">{errors.confirmPassword}</div>}
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    onClick={handleResetPassword}
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        RESETTING PASSWORD... PLEASE WAIT
                        <FaSpinner className="fa-spin" style={{ marginLeft: "5px", animation: "spin 1s linear infinite" }} />
                      </>
                    ) : (
                      "RESET PASSWORD"
                    )}
                  </button>
                  <div className="login-group mt-5 d-flex justify-content-center align-items-center">
                    {results === 'Failed' && (
                      <div className="alert alert-warning w-100 text-center">
                        {resultsMessage}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default PasswordReset;
