import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../ApiCall";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faComment, faQrcode, faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';
import { postSendEmailCode, postSendSmsCode, postVerifySmsCode } from '../Security/Mfa';
import "../../styles/MfaPreferences.scss";

function MfaPreferences({ pageName }) {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state?.customer)
  const [UserData, setUserData] = useState([]);

  const [FieldError, setFieldError] = useState([]);
  const MfaSms = 'MfaSms';
  const MfaEmail = 'MfaEmail';

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^(\d{10}|)$/;
    return phoneNumberRegex.test(phoneNumber);
  };

  const ErrorCodeData = (FieldName, FieldValue) => {
    setFieldError((prevFieldError) => ({
      ...prevFieldError,
      [FieldName]: FieldValue
    }));
  }

  const handleSaveChange = async (ID, FieldName, FieldValue = undefined) => {
    ErrorCodeData('SaveCellPhone', 'Saving');
    document.getElementById('cellPhone').style.border = '1px solid #ced4da';
    const valueToSave = FieldValue !== undefined ? FieldValue : UserData[FieldName];

    if (ID !== undefined && ID !== 'New') {
      setFieldError([]);
      if (FieldName === 'CellPhone') {
        if (!validatePhoneNumber(valueToSave)) {
          ErrorCodeData('CellPhone', 'Please enter a valid phone number');
          document.getElementById('cellPhone').style.border = '1px solid #ced4da';
          return;
        }
      }

      var data = {
        'EditUser': 1,
        'ID': customer?.websiteUserID,
        [FieldName]: valueToSave
      }

      //API call to save changes
    }

    // ErrorCodeData('SaveCellPhone', 'Saved');
    // setTimeout(() => {
    //   ErrorCodeData('SaveCellPhone', '');
    // }, 10000);
  };

  const handleOnChange = (ID, FieldValue, Save = 0) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      [Object.keys(FieldValue)[0]]: Object.values(FieldValue)[0]
    }));

    if (Save === 1) {
      handleSaveChange(ID, Object.keys(FieldValue)[0], Object.values(FieldValue)[0]);
    }
  };

  const UpdateUserData = (FieldName, FieldValue) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      [FieldName]: FieldValue
    }));
  }

  const handleSetup = async (Type) => {
    document.getElementById('cellPhone').style.border = '1px solid #ced4da';
    ErrorCodeData('CellPhone', null);
    if (Type === MfaSms) {
      ErrorCodeData(MfaSms + 'CodeSent', 'Sending')
      if (!UserData?.CellPhone || UserData.CellPhone.length !== 10) {
        document.getElementById('cellPhone').style.border = '1px solid red';
        ErrorCodeData('CellPhone', 'Phone number required to setup sms');
        return;
      }

      const { results, resultsMessage } = await dispatch(postSendSmsCode('+1' + UserData?.CellPhone))
      if (results === 'Successful') {
        ErrorCodeData(MfaSms + 'CodeSent', 'Successful')
      } else {
        ErrorCodeData(MfaSms + 'CodeSent', 'Failed');
      }
    } else if (Type === MfaEmail) {
      ErrorCodeData(MfaEmail + 'CodeSent', 'Sending')
      const { results, resultsMessage } = await dispatch(postSendEmailCode(UserData?.Email))
      if (results === 'Successful') {
        ErrorCodeData(MfaEmail + 'CodeSent', 'Successful')
      } else {
        ErrorCodeData(MfaEmail + 'CodeSent', 'Failed');
      }
    }

    UpdateUserData('Show' + Type + 'Setup', false)
    UpdateUserData('Show' + Type + 'Code', true)
  }

  const HandleSaveChanges = async (FieldName, FieldValue) => {
    var data = {
      'EditUser': 1,
      'ID': customer?.websiteUserID,
      [FieldName]: FieldValue
    }

    //api call save changes
  }

  const handleCode = async (Type) => {
    //Check if code is valid
    if (Type === MfaSms) {
      const { results, resultsMessage } = await dispatch(postVerifySmsCode('+1' + UserData?.CellPhone, UserData?.SmsCode));

      if (results === 'Successful' || results === 200) {
        ErrorCodeData(MfaSms + 'CodeVerify', 'Successful')
      } else {
        ErrorCodeData(MfaSms + 'CodeVerify', 'Failed');
        return;
      }
    } else if (Type === MfaEmail) {
      var data = {
        'RequestType': 'Verify',
        'Email': UserData?.Email,
        'CodeToVerify': UserData?.EmailCode
      }
      const response = await ApiCall('usp_UserProfile_MfaEmail', data)
      const results = response?.data?.Table[0]["Results"];

      if (results === 'Successful') {
        ErrorCodeData(MfaEmail + 'CodeVerify', 'Successful')
      } else {
        ErrorCodeData(MfaEmail + 'CodeVerify', 'Failed');
        return;
      }
    }

    HandleSaveChanges('Mfa' + Type, 1)
    UpdateUserData('Show' + Type + 'Code', false)
  }

  const handleDisableConfirmation = (Action, Type) => {
    if (Action === 'confirmation') {
      UpdateUserData('Show' + Type + 'Confirmation', true)
    } else if (Action === 'confirm') {
      HandleSaveChanges('Mfa' + Type, 0)
      UpdateUserData('Show' + Type + 'Confirmation', false)
    } else if (Action === 'cancel') {
      UpdateUserData('Show' + Type + 'Confirmation', false)
    }
  };

  return (
    <div>
      {!customer && customer?.websiteUserID <= 0 && (
        <div className="container mt-4">
          <div className="row">
            <div className="alert alert-danger" role="alert">
              <p>User not found</p>
            </div>
          </div>
        </div>
      )}
      <div className="container mt-4">
        <div>
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label fw-bold text-sm-end text-start">Name</label>
            <label className="col-sm-6 col-form-label text-start">{customer?.Name}</label>
          </div>
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label fw-bold text-sm-end text-start">Email</label>
            <label className="col-sm-6 col-form-label text-start">{customer?.Email}</label>
          </div>
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label fw-bold text-sm-end text-start">Cell Phone</label>
            <div className="col-sm-6" style={{ position: 'relative' }}>
              <input
                type="text"
                id="cellPhone"
                className="form-control"
                maxLength={10}
                value={UserData?.CellPhone || ''}
                onChange={(e) => handleOnChange(customer?.websiteUserID, { CellPhone: e.target.value })}
                onBlur={() => handleSaveChange(customer?.websiteUserID, 'CellPhone')} />
              {FieldError?.SaveCellPhone === 'Saving' && (
                <FontAwesomeIcon icon={faSpinner} className="saving-icon" style={{ position: 'absolute', right: '-5px', top: '0%' }} spin />
              )}
              {FieldError?.SaveCellPhone === 'Saved' && (
                <FontAwesomeIcon icon={faCheck} className="green-checkmark" style={{ position: 'absolute', right: '-5px', top: '0%', color: 'green' }} />
              )}
              <label className="col-form-label text-danger">{FieldError?.CellPhone}</label>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label fw-bold text-sm-end text-start">Multi-Factor<br />Authentication</label>
            <div className="col-sm-6" style={{ textAlign: "Left" }}>
              <p>Use multiple authentication steps to increase security for your account</p>
              <div className="row mb-3">
                <div className="col-sm-6 mt-3">
                  <div className="card border">
                    <div className="card-body mfaTile">
                      <FontAwesomeIcon icon={faComment} className={`mfaIcon ${UserData?.MfaSms ? 'mfaEnabled' : ''}`} />
                      <span className="mfaTitle">Add SMS</span>
                      {UserData?.ShowSmsSetup && <button className="btn btn-secondary" onClick={() => handleSetup(MfaSms)}>
                        Setup
                        {FieldError?.SmsCodeSent === 'Sending' && <i className="fa fa-spinner fa-spin" style={{ marginLeft: "5px" }} />}
                      </button>}
                      {UserData?.ShowSmsCode && (
                        <>
                          <div className="mfaSetupCode">
                            <>
                              {FieldError?.SmsCodeSent === 'Successful' && (
                                <>
                                  <span className="mfaCodeSuccessful" >Code sent to your phone</span>
                                  <input type="text" className="mfaCode" placeholder="Enter Code" onChange={(e) => UpdateUserData(MfaSms + 'Code', e.target.value)} />
                                  <button className="btn btn-primary ms-2" onClick={() => handleCode(MfaSms)}>Submit</button>
                                  {FieldError?.SmsCodeVerify === 'Failed' &&
                                    <span className="mfaCodeFailed">Code not valid</span>
                                  }
                                </>
                              )}
                              {FieldError?.SmsCodeSent === 'Failed' &&
                                <span className="mfaCodeFailed" >Code failed to send please try again later</span>
                              }
                            </>
                          </div>
                        </>
                      )}
                      {UserData?.MfaSms && (
                        <>
                          <span className="mfaEnabled">Enabled</span>
                          <button className="mb-4 btn btn-danger mfaDisable disableButton" onClick={() => handleDisableConfirmation('confirmation', MfaSms)}>Disable</button>
                          {UserData?.ShowSmsConfirmation && (
                            <div className="mt-3 mb-3" style={{ textAlign: 'center' }}>
                              <p>Are you sure you want to disable?</p>
                              <button className="btn btn-danger btn-sm" onClick={() => handleDisableConfirmation('confirm', MfaSms)}>Yes</button>
                              <button className="ms-3 btn btn-secondary btn-sm" onClick={() => handleDisableConfirmation('cancel', MfaSms)}>No</button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 mt-3">
                  <div className="card border">
                    <div className="card-body mfaTile">
                      <FontAwesomeIcon icon={faEnvelope} className={`mfaIcon ${UserData?.MfaEmail ? 'mfaEnabled' : ''}`} />
                      <span className="mfaTitle">Add Email</span>
                      {UserData?.ShowEmailSetup && <button className="btn btn-secondary" onClick={() => handleSetup(MfaEmail)}>
                        Setup
                        {FieldError?.EmailCodeSent === 'Sending' && <i className="fa fa-spinner fa-spin" style={{ marginLeft: "5px" }} />}
                      </button>}
                      {UserData?.ShowEmailCode && (
                        <>
                          <div className="mfaSetupCode">
                            <>
                              {FieldError?.EmailCodeSent === 'Successful' && (
                                <>
                                  <span className="mfaCodeSuccessful" >Code sent to your phone</span>
                                  <input type="text" className="mfaCode" placeholder="Enter Code" onChange={(e) => UpdateUserData(MfaEmail + 'Code', e.target.value)} />
                                  <button className="btn btn-primary ms-2" onClick={() => handleCode(MfaEmail)}>Submit</button>
                                  {FieldError?.EmailCodeVerify === 'Failed' &&
                                    <span className="mfaCodeFailed">Code not valid</span>
                                  }
                                </>
                              )}
                              {FieldError?.EmailCodeSent === 'Failed' &&
                                <span className="mfaCodeFailed" >Code failed to send please try again later</span>
                              }
                            </>
                          </div>
                        </>
                      )}
                      {UserData?.MfaEmail && (
                        <>
                          <span className="mfaEnabled">Enabled</span>
                          <button className="mb-4 btn btn-danger mfaDisable disableButton" onClick={() => handleDisableConfirmation('confirmation', MfaEmail)}>Disable</button>
                          {UserData?.ShowEmailConfirmation && (
                            <div className="mt-3 mb-3" style={{ textAlign: 'center' }}>
                              <p>Are you sure you want to disable?</p>
                              <button className="btn btn-danger btn-sm" onClick={() => handleDisableConfirmation('confirm', MfaEmail)}>Yes</button>
                              <button className="ms-3 btn btn-secondary btn-sm" onClick={() => handleDisableConfirmation('cancel', MfaEmail)}>No</button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MfaPreferences;