import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Order from '../../components/Customer/Order';
import { setShowNavbar, setShowFooter } from "../../Redux/siteWideSettingsSlice";
import '../../styles/PrintOrder.scss';

function PrintOrder() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setShowNavbar(false));
    dispatch(setShowFooter(false));
  }, [dispatch]);
  
  return (
    <div className="print-order">
      <Order OrderType="Orders" />
    </div>
  );
}

export default PrintOrder;
