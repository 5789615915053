import React from 'react';
import { useSelector } from 'react-redux';
import ErrorBoundary from './Error/ErrorBoundary';
import ErrorMessage from '../pages/errors/ErrorMessage';

function Loading() {
  const ImageUrl = useSelector((state) => state?.siteWideSettings?.imageServerUrl);

  return (
    <ErrorBoundary componentName="Loading" fallback={<ErrorMessage />}>
      <div className='loading-gif'>
        <img src={`${ImageUrl}adfiles/8167-TCPLoading_Optimized.gif`} alt='Loading...' />
      </div>
    </ErrorBoundary>
  );
}

export default Loading;
