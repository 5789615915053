import { useState, useMemo } from "react";
import { useParams, useSearchParams, Link } from "react-router-dom";
import ErrorBoundary from "../Error/ErrorBoundary";
import { ApiCall } from "../ApiCall";

function EmailConfirmation() {
  const [Results, setResults] = useState('');
  const [Message, setMessage] = useState('');
  const { resetrowguid } = useParams();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');

  useMemo(() => {
    (async () => {
      var data = {
        ResetRowGuid: resetrowguid
      };
      const response = await ApiCall('usp_Tcp_PasswordConfirmation', data);
      setResults(response?.data?.Table[0]['Results']);
      setMessage(response?.data?.Table[0]['ResultsMessage']);
    })();
  }, [resetrowguid, redirect]);

  return (
    <ErrorBoundary componentName='EmailConfirmation'>
      <div className="d-flex justify-content-center align-items-center mt-5">
        <div className="text-center">
          <h1>Password Confirmation</h1>
          {Results === 'Successful' && (
            <>
              <p>Your email has been confirmed.</p>
              {redirect && (
                <Link
                  to={redirect}
                  className="btn btn-primary mt-3"
                >
                  Return to Previous Page
                </Link>
              )}
            </>
          )}
          {Results === 'Failed' && (<p>{Message}</p>)}
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default EmailConfirmation;