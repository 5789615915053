import React, { useState, useEffect } from "react";
import ErrorBoundary from "../../components/Error/ErrorBoundary";
import ErrorMessage from "../errors/ErrorMessage";
import LoginCustomer from "../../components/Customer/Login";
import SignupCustomer from "../../components/Customer/Signup";
import EmailConfirmationCustomer from "../../components/Customer/EmailConfirmation";
import PasswordResetCustomer from "../../components/Customer/PasswordReset";
import PasswordConfirmationCustomer from "../../components/Customer/PasswordConfirmation";
import Accounts from "../../components/Customer/Accounts";
import { useLocation, useNavigate } from "react-router-dom";
import { updatePageMeta } from "../../Redux/siteWideSettingsSlice";
import { useDispatch } from "react-redux";
import BackgroundImage from "../../components/Customer/LoginBackgroundImg";

import "../../styles/_loginShellCustomer.scss"

function LoginShell() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const pageName = location.pathname.split('/')[2].toLowerCase();
  const [activePage, setActivePage] = useState(pageName);
  dispatch(updatePageMeta("Customer Login"));

  useEffect(() => {
    const newPageName = location.pathname.split('/')[2].toLowerCase();
    setActivePage(newPageName || 'login');
  }, [location.pathname]);

  return (
    <ErrorBoundary componentName="CustomerAuthPage" fallback={<ErrorMessage />}>
      <div className="login-shell">
        {pageName === "login" && <BackgroundImage />}
        {activePage === "login" && <LoginCustomer />}
        {activePage === "signup" && <SignupCustomer />}
        {activePage === "emailconfirmation" && <EmailConfirmationCustomer />}
        {activePage === "passwordreset" && <PasswordResetCustomer pageName={pageName} />}
        {activePage === "passwordconfirmation" && <PasswordConfirmationCustomer />}
        {activePage === "accounts" && <Accounts />}
      </div>
    </ErrorBoundary>
  );
}

export default LoginShell;
