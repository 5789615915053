import React, { useEffect } from 'react';
import FormattedCurrency from '../DataManipulation/FormatCurrency';
import CreditCard from '../../components/CreditCard';
import { useSelector } from 'react-redux';

function MakePayment({ Amount }) {
  const customerProfile = useSelector((state) => state?.customer);
  const [paymentAmount, setPaymentAmount] = React.useState(Amount);

  useEffect(() => {
    setPaymentAmount(Amount);
  }, [Amount]);

  const handleAmountChange = (e) => {
    const value = Number(parseFloat(e.target.value).toFixed(2)) || 0;
    if (value <= Amount && value >= 0) {
      setPaymentAmount(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="mt-4">
      <div className="row">
        <div className="col-md-6">
          <div className="p-3 mb-3 make-payment">
            <h5 className="mb-3">Option #1) Secure Online Payment</h5>
            <form onSubmit={handleSubmit}>
              <div className='mb-2'>
                <label className="d-block">Payment Amount:</label>
                <input
                  type="number"
                  value={paymentAmount}
                  onChange={handleAmountChange}
                  min="0"
                  max={Amount}
                  step="0.01"
                  pattern="\d*\.?\d{0,2}"
                  className="form-control"
                />
                <small className="text-muted d-block mt-1">
                  Maximum amount: <FormattedCurrency value={Amount} />
                </small>
              </div>
              <CreditCard
                Action='Charge'
                CustID={customerProfile?.account}
                Amount={paymentAmount}
                Recaptcha={false}
              />
            </form>
          </div>
        </div>
        <div className="col-md-6">
          <div className="p-3 mb-3 make-payment">
            <h5 className="mb-3">Option #2) Phone Payment</h5>
            <span>Call <span style={{ whiteSpace: "nowrap" }}>1-800-619-1110</span>. Ask to speak to accounts receivable. </span>
            <span>Have a credit card and invoice number handy.</span>
          </div>
          <div className="p-3 make-payment">
            <h5 className="mb-3">Option #3) Mail Check Payment</h5>
            <span>Send To: 102 Boxhorn Dr, Mukwonago, WI 53149 </span>
            <span>Make Payable To: Triple Crown Products </span>
            <span>Please add invoice number(s) to check memo.</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MakePayment;
