import React, { useEffect, useMemo } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import FormattedTitleCase from '../DataManipulation/FormatTitleCase';

import "../../styles/DynamicTabs.scss";

function DynamicTabs() {
  const navigate = useNavigate();
  const location = useLocation();

  const tabsConfig = useMemo(() => ([
    ['profile'],
    ['orders', 'unpaid invoices', 'invoices'],
    ['email your sales associate'],
    ['manage logos'],
    ['security'],
  ]), []);

  const tabAliases = {
    order: 'orders',
    invoice: 'invoices',
    unpaidinvoice: 'unpaidinvoices',
    support: 'emailyoursalesassociate',
  };

  const activeTab = useMemo(() => {
    const pathSegments = location.pathname.split('/');
    let tab = pathSegments[2]?.toLowerCase() || null;
    // Check if the tab has an alias
    if (tab && tabAliases[tab]) {
      tab = tabAliases[tab];
    }

    return tab;
  }, [location.pathname]);

  const pageName = useMemo(() => {
    const foundPage = Object.entries(tabsConfig).find(([_, tabs]) =>
      tabs.some(tab => tab.replace(/\s/g, '').toLowerCase() === activeTab)
    );
    return foundPage ? foundPage[0] : null;
  }, [tabsConfig, activeTab]);

  const tabs = useMemo(() => tabsConfig[pageName] || [], [pageName, tabsConfig]);

  useEffect(() => {
    if (tabs.length > 0 && !activeTab) {
      navigate(`/customer/${tabs[0].replace(/\s/g, '')}`);
    }
  }, [tabs, activeTab, navigate]);

  const getTabLabel = (tab) => {
    if (tab === 'security') {
      return 'Reset Password';
    }
    return tab;
  };

  return (
    <div className="tabs-wrapper">
      <div className="tab-container">
        {tabs.map((tab) => {
          const tabWithoutSpace = tab.replace(/\s/g, '').toLowerCase();
          let toPath = `/customer/${tabWithoutSpace}`;

          if (tabWithoutSpace === 'emailyoursalesassociate') {
            toPath = '/customer/support';
          } else if (tabWithoutSpace === 'multi-factorauthentication') {
            toPath = '/customer/mfapreferences';
          }

          return (
            <Link
              key={tab}
              to={toPath}
              className={`tab ${activeTab === tabWithoutSpace ? 'active' : ''}`}
            >
              <FormattedTitleCase value={getTabLabel(tab)} />
            </Link>
          );
        })}
        <span className="spacer-tab"></span>
      </div>
    </div>
  );
}

export default DynamicTabs;
