import React from "react";
import AELogin from "../../../components/AddEdit/AELogin";
import { useSelector } from "react-redux";

function AEHome() {
  const isStaffLoggedIn = useSelector((state) => state?.siteWideSettings?.EmployeeUser?.LoggedIn);

  return (
    <div className="d-flex justify-content-center mt-5">
      {
        isStaffLoggedIn
          ? <div>SUCCESS: You are logged in</div>
          : <AELogin />
      }
    </div>
  );
}

export default AEHome;
