import React, { useState, useEffect } from 'react';
import Pagination from "react-responsive-pagination";

export default function Paginations(props) {
  const [displayPaging, setDisplayPaging] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setDisplayPaging(false);
  //   }, 500);
  // }, []);

  return (
    <div>
      {displayPaging ? (
        <Pagination
          current={props.activePage}
          total={Math.ceil(
            props.totalItemsCount / props.itemsCountPerPage
          )}
          onPageChange={props.handlePageChange}
        />
      ) : null}
    </div>
  )
}


