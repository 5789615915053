import React, { useState, useEffect } from "react";
import DynamicTabs from "./DynamicTabs";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../ApiCall";
import Loading from "../loading";
import FormattedCurrency from "../DataManipulation/FormatCurrency";
import FormattedDate from "../DataManipulation/FormatDate";
import { ChangeActiveCart } from "../../Redux/cartSlice";

function SavedCarts() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [SavingCart, setSavingCart] = useState('');
  const [isEditMode, setIsEditMode] = useState('');
  const [SavedCarts, setSavedCarts] = useState([]);
  const customerProfile = useSelector(state => state?.customer);
  const Cart = useSelector(state => state?.cart);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const fetchSavedCarts = async () => {
      setIsLoading(true);
      var data = {
        'OrderType': 'Carts',
        'WebsiteId': '1',
        'WebsiteUserId': customerProfile?.websiteUserID,
        'CustID': customerProfile?.account
      }
      const response = await ApiCall('usp_Webstore_Orders', data, 'Tcp');
      const carts = response?.data?.Table;
      setSavedCarts(carts);
      setIsLoading(false);
    };
    fetchSavedCarts();
  }, [customerProfile?.websiteUserID, customerProfile?.account])

  const handleEdit = (WebOrderId) => {
    setIsEditMode(WebOrderId);
    setFormData(SavedCarts.find(SavedCart => SavedCart.WebOrderID === WebOrderId));
    if (WebOrderId === '') {
      setFormData(null);
    }
  }

  const handleSaveCartName = async () => {
    setSavingCart(formData?.WebOrderID);
    setIsEditMode('');
    var data = {
      'WebOrderID': formData?.WebOrderID,
      'CartName': formData?.CartName
    }
    const response = await ApiCall('usp_Webstore_CartName_Edit', data);
    if (response.statusCode === 200) {
      const updatedCarts = [...SavedCarts];
      const cartIndex = updatedCarts.findIndex(cart => cart.WebOrderID === formData.WebOrderID);
      if (cartIndex !== -1) {
        updatedCarts[cartIndex].CartName = formData.CartName;
        setSavedCarts(updatedCarts);
      }
    }

    setSavingCart('');
  }

  const handleCartRestore = async (WebOrderID) => {
    await dispatch(ChangeActiveCart(WebOrderID));
    //setActiveCart(WebOrderID);
    //Update local value for weborder id and go to cart page
  }

  const handleCartCreation = async () => {
    setIsLoading(true);

    setIsLoading(false);
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  }

  return (
    <>
      <DynamicTabs />
      {isLoading && <Loading />}
      {!isLoading && SavedCarts.length === 0 && (
        <div>No orders</div>
      )}
      {!isLoading && SavedCarts.length > 0 && (
        <>
          <div className="text-end">
            <button type="button" className="btn btn-primary" onClick={() => handleCartCreation()}>Create New Cart</button>
          </div>
          <div className="orders-container">
            {SavedCarts.map((SavedCart, index) => (
              <div className="order-row" key={SavedCart?.WebOrderID}>
                <div className="order-card">
                  {SavingCart === SavedCart?.WebOrderID && (
                    <Loading />
                  )}
                  <div className="order-details">
                    <p className="order-key">Cart Name:</p>
                    {isEditMode === SavedCart?.WebOrderID ? (
                      <input type="text" id="CartName" className="order-value" value={formData?.CartName} defaultValue={SavedCart?.CartName} onChange={handleChange} />
                    ) : (
                      <p className="order-value">{SavedCart?.CartName}</p>
                    )}
                    <p className="order-key">Date Created:</p><p className="order-value"><FormattedDate date={SavedCart?.DateCreated} dateFormat='MM/dd/yyyy' /></p>
                    <p className="order-key">Item Quantity:</p><p className="order-value">{SavedCart?.ItemCount}</p>
                  </div>
                  <div className="order-summary">
                    <p className="order-key">Subtotal:</p><p className="order-value"><FormattedCurrency value={SavedCart?.SubTotal} /></p>
                    <p className="order-key">Shipping:</p><p className="order-value"><FormattedCurrency value={SavedCart?.Shipping} /></p>
                    <p className="order-key">Taxes:</p><p className="order-value"><FormattedCurrency value={SavedCart?.Taxes} /></p>
                    <p className="order-key">Total:</p><p className="order-value"><FormattedCurrency value={SavedCart?.BalanceDue} /></p>
                  </div>
                  <div className="order-actions">
                    {isEditMode === SavedCart?.WebOrderID ? (
                      <>
                        <button type="button" className="edit-cart" onClick={() => handleSaveCartName('New Name', 'WebOrderID')}>Save</button>
                        <button type="button" className="edit-cart" onClick={() => handleEdit('')}>Cancel</button>
                      </>
                    ) : (
                      <>
                        {Cart?.WebOrderID === SavedCart?.WebOrderID ? (
                          <p className="active-cart">Current Cart</p>
                        ) : (
                          <button type="button" className="edit-cart" onClick={() => handleCartRestore(SavedCart?.WebOrderID)}>Activate Cart</button>
                        )}
                        <button type="button" className="edit-cart" onClick={() => handleEdit(SavedCart?.WebOrderID)}>Edit Name</button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  )
}

export default SavedCarts;