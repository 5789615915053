import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CCChargeUrl } from '../config/apiconfig';
import { cryptAPIKey, encryptUsingAES256Base64 } from '../components/Security/crypt';
import FormattedCurrency from './DataManipulation/FormatCurrency';
import FormattedDate from './DataManipulation/FormatDate';
import { LogError } from "./Error/LogError";

function CreditCard({ Action, CustID, Amount, Recaptcha }) {
  const packageJson = require('../../package.json');
  const [successMessage, setSuccessMessage] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [refNum, setRefNum] = useState('');
  const [date, setDate] = useState('');
  const [authAmount, setAuthAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  const [CreditCard, setCreditCard] = useState({
    cardName: "",
    nameOnCard: "",
    cardNumber: "",
    expiryMonth: "",
    expiryYear: "",
    cvv: "",
    email: "",
    cardType: "",
    zipCode: "",
  });

  const [errors, setErrors] = useState({
    cardName: '',
    nameOnCard: "",
    cardNumber: '',
    expiry: '',
    cvv: '',
    email: '',
    cardType: '',
    zipCode: '',
  });

  const handleCreditCardChange = (e) => {
    const { name, value } = e.target;
    setCreditCard({
      ...CreditCard,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const validateExpiry = (month, year) => {
    let errorMessage = '';
    const currentYear = new Date().getFullYear() % 100;
    const currentMonth = new Date().getMonth() + 1;

    if (!month || !year) {
      return 'Expiry date is required';
    }

    if (Number(month) < 1 || Number(month) > 12) {
      errorMessage = 'Invalid month';
    } else if (Number(year) < currentYear || (Number(year) === currentYear && Number(month) < currentMonth)) {
      errorMessage = 'Expiry date is in the past';
    } else if (Number(year) > currentYear + 75) {
      errorMessage = 'Year is more than 75 years in the future';
    }

    return errorMessage;
  };

  const handleExpiryChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = value.replace(/\D/, '');
    let newExpiry = {
      ...CreditCard,
      [name]: formattedValue,
    };

    setCreditCard(newExpiry);

    const expiryError = validateExpiry(newExpiry.expiryMonth, newExpiry.expiryYear);
    setErrors({
      ...errors,
      expiry: expiryError,
    });
  };

  const handleSubmit = async (e) => {
    // Validate all fields
    let valid = true;
    let newErrors = {};

    if (!CreditCard.nameOnCard) {
      newErrors.nameOnCard = 'Name on the card is required';
      valid = false;
    }
    if (!CreditCard.cardNumber) {
      newErrors.cardNumber = 'Card number is required';
      valid = false;
    }
    const expiryError = validateExpiry(CreditCard.expiryMonth, CreditCard.expiryYear);
    if (expiryError) {
      newErrors.expiry = expiryError;
      valid = false;
    }
    if (!CreditCard.cvv) {
      newErrors.cvv = 'Security code is required';
      valid = false;
    }
    if (!CreditCard.zipCode) {
      newErrors.zipCode = 'Zip code is required';
      valid = false;
    }
    if (Action === 'Charge' && !CreditCard.email) {
      newErrors.email = 'Email is required';
      valid = false;
    }
    if (Action === 'AddCard' && !CreditCard.cardType) {
      newErrors.cardType = 'Card type is required';
      valid = false;
    }

    setErrors(newErrors);

    if (valid) {
      const requestHeaders = {
        'Content-Type': 'application/json; charset=utf-8',
        'X-ApiKey': cryptAPIKey(process.env.REACT_APP_SECRET_KEY)
      };

      const data = {
        CardName: CreditCard.cardName,
        NameOnCard: CreditCard.nameOnCard,
        CardNumber: CreditCard.cardNumber,
        ExpMonth: CreditCard.expiryMonth,
        ExpYear: CreditCard.expiryYear,
        Cvv: CreditCard.cvv,
        CardType: CreditCard.cardType,
        CustID: CustID,
        PostalCode: CreditCard.zipCode,
        ...(Action === 'Charge' && { Amount: Amount, Email: CreditCard.email }),
        SoftwareName: "TcpReact",
        SoftwareVersion: packageJson.version,
        WebsiteID: "1",
      };
      const encryptedData = encryptUsingAES256Base64(JSON.stringify(data));

      try {
        const response = await axios.post(CCChargeUrl, encryptedData, { headers: requestHeaders, timeout: 10000 });
        const { result, error, authCode, refNum, date: encodedDate, authAmount } = response.data;

        if (typeof result === 'string' && result.trim() === 'Approved') {
          if (typeof encodedDate === 'string') {
            const decodedDate = decodeURIComponent(encodedDate);

            if (decodedDate) {
              const formattedDate = decodedDate.replace(/\+/g, ' ');
              const [datePart, timePart, meridian] = formattedDate.split(' ');
              const [month, day, year] = datePart.split('/');
              const [hours, minutes, seconds] = timePart.split(':');

              let parsedHours = parseInt(hours, 10);
              if (meridian === 'PM' && parsedHours < 12) {
                parsedHours += 12;
              } else if (meridian === 'AM' && parsedHours === 12) {
                parsedHours = 0;
              }

              const parsedDate = new Date(year, month - 1, day, parsedHours, minutes, seconds);
              setDate(parsedDate);

              setAuthAmount(authAmount);
              setSuccessMessage('Credit card charged successfully!');
              setAuthCode(authCode);
              setRefNum(refNum);

              // Reset form fields
              setCreditCard({
                cardName: "",
                nameOnCard: "",
                cardNumber: "",
                expiryMonth: "",
                expiryYear: "",
                cvv: "",
                email: "",
                cardType: "",
                zipCode: "",
              });
              setErrors({
                cardName: '',
                nameOnCard: '',
                cardNumber: '',
                expiry: '',
                cvv: '',
                email: '',
                cardType: '',
                zipCode: '',
              });
            } else {
              setErrorMessage('Error encountered with transaction date processing.');
            }
          } else {
            setErrorMessage('Issue detected with transaction date.');
          }
        } else if (result === 'Error') {
          const formattedError = typeof error === 'string' ? error.replace(/\+/g, ' ') : 'Transaction processing encountered an error.';
          setErrorMessage(formattedError);
        }
      } catch (error) {
        await LogError(error, 'Credit Card', 'CreditCard');
        setErrorMessage('Transaction processing failed, please try again.');
      }
    }
  };

  const handleNewPayment = () => {
    setSuccessMessage('');
    setAuthCode('');
    setRefNum('');
    setDate('');
    setAuthAmount(0);
    setCreditCard({
      cardName: "",
      nameOnCard: "",
      cardNumber: "",
      expiryMonth: "",
      expiryYear: "",
      cvv: "",
      email: "",
      cardType: "",
      zipCode: "",
    });
    setErrors({
      cardName: '',
      nameOnCard: '',
      cardNumber: '',
      expiry: '',
      cvv: '',
      email: '',
      cardType: '',
      zipCode: '',
    });
  };

  const detectCardType = (number) => {
    const cardPatterns = {
      Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      MasterCard: /^5[1-5][0-9]{14}$/,
      AmericanExpress: /^3[47][0-9]{13}$/,
    };

    for (const cardType in cardPatterns) {
      if (cardPatterns[cardType].test(number)) {
        return cardType;
      }
    }
    return '';
  };

  useEffect(() => {
    if (CreditCard.cardNumber) {
      const cardType = detectCardType(CreditCard.cardNumber);
      setCreditCard((prevState) => ({
        ...prevState,
        cardType,
      }));
    }
  }, [CreditCard.cardNumber]);

  useEffect(() => {
    if (CreditCard.cardType) {
      setCreditCard((prevState) => ({
        ...prevState,
        cvv: prevState.cvv.slice(0, CreditCard.cardType === 'AmericanExpress' ? 4 : 3),
      }));
    }
  }, [CreditCard.cardType]);

  return (
    <div>
      {errorMessage && (
        <div className="alert alert-danger">
          {errorMessage}
        </div>
      )}
      {successMessage && (
        <>
          <div className="alert alert-success">
            <p>Credit card charged successfully!</p>
            {authCode && (
              <div>
                <strong>Auth Code: </strong>
                {authCode}
              </div>
            )}
            {refNum && (
              <div>
                <strong>Ref Num: </strong>
                {refNum}
              </div>
            )}
            {date && (
              <div>
                <strong>Transaction Date: </strong>
                <FormattedDate date={date} dateFormat="MM-dd-yyyy HH:mm:ss" timeZone="UTC" />
              </div>
            )}
            {authAmount !== 0 && authAmount && (
              <div>
                <strong>Amount: </strong>
                <FormattedCurrency value={authAmount} />
              </div>
            )}
          </div>
          <button
            type="button"
            className="btn btn-primary mt-3 w-100"
            onClick={handleNewPayment}
          >
            Make Another Payment
          </button>
        </>
      )}
      {!successMessage && (
        <>
          {Action === 'AddCard' && (
            <div className="mb-2">
              <div className="input-group">
                <input
                  type="text"
                  name="cardName"
                  className={`form-control ${errors.cardName ? 'border-danger' : ''}`}
                  placeholder="Nickname for card"
                  value={CreditCard?.cardName}
                  onChange={handleCreditCardChange}
                />
                {errors.cardName && <div className="error-message text-danger">{errors.cardName}</div>}
              </div>
            </div>
          )}
          <div className="mb-2">
            <input
              type="text"
              name="nameOnCard"
              className={`form-control ${errors.nameOnCard ? 'border-danger' : ''}`}
              placeholder="Name on card"
              value={CreditCard?.nameOnCard}
              onChange={handleCreditCardChange}
              autoComplete='cc-name'
              required
            />
            {errors.nameOnCard && <div className="error-message text-danger">{errors.nameOnCard}</div>}
          </div>
          <div className="mb-2">
            <input
              type="text"
              name="cardNumber"
              className={`form-control ${errors.cardNumber ? 'border-danger' : ''}`}
              placeholder="Card Number"
              value={CreditCard?.cardNumber}
              onChange={handleCreditCardChange}
              autoComplete='cc-number'
              required
            />
            {errors.cardNumber && <div className="error-message text-danger">{errors.cardNumber}</div>}
          </div>
          <div className="mb-2">
            <select
              name="cardType"
              className={`form-control ${errors.cardType ? 'border-danger' : ''}`}
              value={CreditCard?.cardType}
              onChange={handleCreditCardChange}
              autoComplete='cc-type'
              required
            >
              <option value="">Select Card Type</option>
              <option value="Visa">Visa</option>
              <option value="MasterCard">MasterCard</option>
              <option value="AmericanExpress">American Express</option>
            </select>
            {errors.cardType && <div className="error-message text-danger">{errors.cardType}</div>}
          </div>
          <div className="row mb-2">
            <div className="col">
              <div className="input-group">
                <input
                  type="text"
                  name="expiryMonth"
                  className={`form-control me-1 ${errors.expiry ? 'border-danger' : ''}`}
                  placeholder="MM"
                  value={CreditCard?.expiryMonth}
                  onChange={handleExpiryChange}
                  autoComplete='cc-exp-month'
                  required
                  maxLength="2"
                />
                <input
                  type="text"
                  name="expiryYear"
                  className={`form-control ${errors.expiry ? 'border-danger' : ''}`}
                  placeholder="YY"
                  value={CreditCard?.expiryYear}
                  onChange={handleExpiryChange}
                  autoComplete='cc-exp-year'
                  required
                  maxLength="2"
                />
                {errors.expiry && <div className="error-message text-danger">{errors.expiry}</div>}
              </div>
            </div>
            <div className="col">
              <input
                type="text"
                name="cvv"
                className={`form-control ${errors.cvv ? 'border-danger' : ''}`}
                placeholder="Security Code"
                value={CreditCard?.cvv}
                onChange={handleCreditCardChange}
                autoComplete='cc-csc'
                required
                maxLength={CreditCard.cardType === 'AmericanExpress' ? 4 : 3}
              />
              {errors.cvv && <div className="error-message text-danger">{errors.cvv}</div>}
            </div>
          </div>
          <div className="mb-2">
            <input
              type="text"
              name="zipCode"
              className={`form-control ${errors.zipCode ? 'border-danger' : ''}`}
              placeholder="Zip Code"
              value={CreditCard?.zipCode}
              onChange={handleCreditCardChange}
              required
            />
            {errors.zipCode && <div className="error-message text-danger">{errors.zipCode}</div>}
          </div>
          {Action === 'Charge' && (
            <div className="mb-2">
              <div className="input-group">
                <input
                  type="email"
                  name="email"
                  className={`form-control ${errors.email ? 'border-danger' : ''}`}
                  placeholder="Email Receipt"
                  value={CreditCard?.email}
                  onChange={handleCreditCardChange}
                  required
                />
                {errors.email && <div className="error-message text-danger">{errors.email}</div>}
              </div>
            </div>
          )}
          <button
            type="button"
            className="btn btn-primary w-100"
            onClick={handleSubmit}
          >
            {Action === 'Charge' && 'Submit Secure Online Payment'}
            {Action === 'AddCard' && 'Add Card'}
          </button>
        </>
      )}
    </div>
  );
}

export default CreditCard;
