import { useState, useEffect } from "react";
import { useParams, useSearchParams, Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ErrorBoundary from "../Error/ErrorBoundary";
import { ApiCall } from "../ApiCall";
import { ProfileLogin, Accounts } from '../../Redux/customerSlice';

function PasswordConfirmation() {
  const [Results, setResults] = useState('');
  const [Message, setMessage] = useState('');
  const { resetrowguid } = useParams();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countdown, setCountdown] = useState(20);

  useEffect(() => {
    const fetchData = async () => {
      if (resetrowguid) {
        const data = {
          ResetRowGuid: resetrowguid
        };
        const response = await ApiCall('usp_Tcp_PasswordConfirmation', data);
        if (response?.data?.Table[0]) {
          const LoginResults = response?.data?.Table[0]['Results'];
          setResults(response.data.Table[0].Results);
          setMessage(response.data.Table[0].ResultsMessage);
          const UserProfile = response.data.Table1;

          // If password reset was successful, handle login
          if (LoginResults === 'Successful') {
            UserProfile[0]['isLoggedIn'] = true;
            UserProfile[0]['keepSignedIn'] = false;
            await dispatch(ProfileLogin(UserProfile));

            // Check for MFA requirements
            if (
              (UserProfile[0]?.MfaAuthenticator === true ||
                UserProfile[0]?.MfaSms === true ||
                UserProfile[0]?.MfaEmail === true) &&
              UserProfile[0]?.MfaLoggedIn === false
            ) {
              navigate('/customer/login/Mfa');
              return;
            }

            // Get accounts data
            const dataA = {
              'Email': UserProfile[0].Email
            };
            const responseA = await ApiCall('tq_ReturnCustomersForEmail', dataA, 'Tcp');
            const AccountsData = responseA?.data?.Table;
            await dispatch(Accounts(AccountsData));
          }
        }
      }
    };

    fetchData();
  }, [resetrowguid, dispatch, navigate, redirect]);

  useEffect(() => {
    let timer;
    if (Results === 'Successful' && countdown > 0) {
      timer = setInterval(() => {
        setCountdown(prev => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      navigate(redirect || '/customer/login');
    }
    return () => clearInterval(timer);
  }, [countdown, Results, navigate, redirect]);

  return (
    <ErrorBoundary componentName='PasswordConfirmation'>
      <div className="d-flex justify-content-center align-items-center mt-5">
        <div className="text-center">
          <h1>Password Confirmation</h1>
          {Results === 'Successful' && (
            <>
              <p>Your password has been successfully reset.</p>
              <p>Redirecting in {countdown} seconds...</p>
              <Link
                to={redirect || '/customer/login'}
                className="btn btn-primary mt-3"
              >
                {(() => {
                  if (redirect?.includes('/invoice/')) return 'Go to Invoice';
                  if (redirect?.includes('/unpaidinvoice/')) return 'Go to Unpaid Invoice';
                  if (redirect?.includes('/order/')) return 'Go to Order';
                  if (redirect?.includes('/payment/')) return 'Go to Payment';
                  if (redirect?.includes('/account/')) return 'Go to Account';
                  return 'Go to Login Page';
                })()} Now
              </Link>
            </>
          )}
          {Results === 'Failed' && <p>{Message}</p>}
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default PasswordConfirmation;
