import React, { useEffect, useState } from "react";
import "../../styles/PaymentMethods.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../loading";
import { ApiCall } from "../ApiCall";
import CreditCard from '../../components/CreditCard';

function PaymentMethodCustomer() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const customerProfile = useSelector((state) => state?.customer);
  const [CreditCards, setCreditCards] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editingName, setEditingName] = useState("");
  const [SavingCard, setSavingCard] = useState(false);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      setIsLoading(true);
      const data = { 'CustID': customerProfile?.account }
      const response = await ApiCall('usp_CustomerPaymentMethods', data, 'Tcp');
      setCreditCards(response?.data?.Table || []);
      setIsLoading(false);
    };

    fetchPaymentMethods();
  }, [dispatch, customerProfile?.account]);

  const handleEdit = (CreditCardID) => {
    setEditingId(CreditCardID);
    const method = CreditCards.find(CreditCard => CreditCard?.CreditCardID === CreditCardID);
    setEditingName(method.CardName);
  };

  const handleNameChange = (event) => {
    setEditingName(event.target.value);
  };

  const handleSave = (CreditCardID) => {
    setSavingCard(true);
    const updatedPaymentMethods = CreditCards.map(CreditCard =>
      CreditCard?.CreditCardID === CreditCardID ? { ...CreditCard, CardName: editingName } : CreditCard
    );

    // TODO: Save nickname to database and remove timeout
    setCreditCards(updatedPaymentMethods);
    setTimeout(() => {
      setSavingCard(false);
      setEditingId(null);
    }, 2000);
  };

  const handleDelete = (CreditCardID) => {
    const updatedPaymentMethods = CreditCards.filter(CreditCard => CreditCard?.CreditCardID !== CreditCardID);
    setCreditCards(updatedPaymentMethods);
  };

  const handleSetDefault = () => {
    console.log('Set as default payment method');
  };

  const renderSavedCard = (CreditCard) => (
    <div className="payment-method-card" key={CreditCard?.CreditCardID}>
      <div className="payments-card-header">
        {editingId === CreditCard?.CreditCardID ? (
          <div className="payments-card-name">
            {SavingCard && (
              <Loading />
            )}
            <div className="row g-2 align-items-center">
              <div className="col-10">
                <input className="form-control" type="text" value={editingName} onChange={handleNameChange} />
              </div>
              <div className="col-2">
                <button className="btn btn-primary" onClick={() => handleSave(CreditCard?.CreditCardID)}>Save</button>
              </div>
            </div>
          </div>
        ) : (
          <div className="payments-card-name">{CreditCard?.CardName || '**** **** **** ' + CreditCard?.CardNumber}</div>
        )}
        <div className="edit-payment-link" onClick={() => handleEdit(CreditCard?.CreditCardID)}>Edit Card Name</div>
      </div>
      <div className="row">
        <div className="col-md-2 col-6 text-nowrap">
          <span>Card Type:</span>
        </div>
        <div className="col-md-10 col-6 text-nowrap">
          <span className="fw-bold">{CreditCard?.CardType}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 col-6 text-nowrap">
          <span>Card Number:</span>
        </div>
        <div className="col-md-10 col-6 text-nowrap">
          <span className="fw-bold">**** **** **** {CreditCard?.CardNumber}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 col-6 text-nowrap">
          <span>Name On Card:</span>
        </div>
        <div className="col-md-10 col-6 text-nowrap">
          <span className="fw-bold">{CreditCard?.NameOnCard}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 col-6 text-nowrap">
          <span>Exp. Date:</span>
        </div>
        <div className="col-md-10 col-6 text-nowrap">
          <span className="fw-bold">{CreditCard?.ExpirationMonth}/{CreditCard?.ExpirationYear}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 col-6 text-nowrap">
          <span>Zip:</span>
        </div>
        <div className="col-md-10 col-6 text-nowrap">
          <span className="fw-bold">{CreditCard?.PostalCode}</span>
        </div>
      </div>
      {editingId === CreditCard?.CreditCardID && (
        <div className="delete-icon" onClick={() => handleDelete(CreditCard?.CreditCardID)}>
          Remove Card <FontAwesomeIcon icon={faTrashAlt} />
        </div>
      )}
    </div>
  );

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="mb-5">
            {CreditCards.length > 0 ?
              CreditCards.map(paymentMethod => renderSavedCard(paymentMethod))
              : <p>You have no cards on file</p>
            }
          </div>
          <div className="mb-5" style={{ maxWidth: "500px" }}>
            <CreditCard Action='AddCard' CustID={customerProfile?.account} />
          </div>
        </>
      )}
    </>
  );
}

export default PaymentMethodCustomer;
