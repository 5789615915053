import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { Account } from '../../Redux/customerSlice';
import ProfileCustomer from "../../components/Customer/Profile";
import Orders from "../../components/Customer/Orders";
import Order from "../../components/Customer/Order";
import PaymentMethodCustomer from "../../components/Customer/PaymentMethod";
import SupportCustomer from "../../components/Customer/Support";
import ManageLogosCustomer from "../../components/Customer/ManageLogos";
import CustomerSecurity from "../../components/Customer/Security";
import Preferences from '../../components/Customer/Preferences';
import SavedCarts from '../../components/Customer/SavedCarts';
import TaxExemption from '../../components/Customer/TaxExemption';
import "../../styles/CustomerShell.scss";

function CustomerShell() {
  const dispatch = useDispatch();
  const location = useLocation();
  const customerFirstName = useSelector((state) => state?.customer?.firstName);
  const customerAccount = useSelector((state) => state?.customer?.account);
  const customerAccounts = useSelector((state) => state?.customer?.accounts);
  const [selectedAccount, setSelectedAccount] = useState(customerAccount);

  const pageName = location.pathname.split('/')[2].toLowerCase();
  const [activePage, setActivePage] = useState(pageName || 'profile');

  useEffect(() => {
    const newPageName = location.pathname.split('/')[2].toLowerCase();
    setActivePage(newPageName || 'profile');
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    setSelectedAccount(customerAccount);
  }, [customerAccount]);

  const handleAccountChange = (event) => {
    const Selected = event.target.value;
    const AccountFound = customerAccounts.filter(account => account.CustID === Selected)[0];
    setSelectedAccount(Selected);
    dispatch(Account(AccountFound));
  };

  return (
    <div className="account-container">
      <div className="menuAndHeader-wrapper">
        <div className="menu">
          {customerAccounts && customerAccounts.length > 1 && (
            <select value={selectedAccount} onChange={handleAccountChange} className="form-select">
              {selectedAccount === '' && (
                <option value="" disabled>Select Account</option>
              )}
              {customerAccounts.map((account) => (
                <option key={account.CustID} value={account.CustID}>{account.CustName}</option>
              ))}
            </select>
          )}
          {customerAccounts && customerAccounts.length === 1 && (
            <span>{customerAccounts[0].CustName}</span>
          )}
        </div>
        <div className="nameHeader">
          {customerFirstName ? <h2>Hello, {customerFirstName}</h2> : <h2>Welcome Back!</h2>}
        </div>
      </div>
      <div className="pages">
        <div className="customer-account-pages">
          {activePage === "profile" && <ProfileCustomer />}
          {activePage === "taxexemption" && <TaxExemption />}
          {activePage === "orders" && <Orders OrderType='Orders' />}
          {activePage === "order" && <Order OrderType='Orders' />}
          {activePage === "unpaidinvoices" && <Orders OrderType='UnpaidInvoices' />}
          {activePage === "unpaidinvoice" && <Order OrderType='UnpaidInvoices' />}
          {activePage === "invoices" && <Orders OrderType='Invoices' />}
          {activePage === "invoice" && <Order OrderType='Invoices' />}
          {activePage === "paymentmethod" && <PaymentMethodCustomer />}
          {activePage === "support" && <SupportCustomer />}
          {activePage === "managelogos" && <ManageLogosCustomer />}
          {activePage === "security" && <CustomerSecurity />}
          {activePage === "mfapreferences" && <CustomerSecurity />}
          {activePage === "savedcarts" && <SavedCarts />}
          {activePage === "preferences" && <Preferences />} {/* TODO: Create (or eliminate)Preferences component */}
        </div>
      </div>
    </div>
  );
}

export default CustomerShell;